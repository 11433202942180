import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/docs-src/src/layouts/MDXPageLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`path: '/guidelines/overview/'
title: 'Overview'`}</p>


    <p className="Lead">Harmonium is a Design System for teams that move fast. Scalable design tokens, accessible markup, and a comprehensive component library allow for harmonious user experiences.</p>
    <br />
    <div className="DesignPrinciples">
  <div className="rev-Card rev-Card--primary">
    <div className="rev-Row rev-Row--flex rev-Row--alignStretch">
      <div className="rev-Col rev-Col--small12 rev-Col--large7">
        <h2>Scalable</h2>
        <p className="Lead"><strong>Good design is flexible.</strong> Enable products to gracefully accommodate new features, markets, and platforms.</p>
      </div>
      <div className="rev-Col rev-Col--small12 rev-Col--large5 SectionDivider"> 
        <h4>Methods</h4>
        <ul className="BulletedList">
          <li>Provide space for innovation and iteration</li>
          <li>Help others see the big picture and plan for scale</li>
          <li>Create design solutions across different media</li>
        </ul>
      </div>
    </div>
  </div>
  <div className="rev-Card rev-Card--primary">
    <div className="rev-Row rev-Row--flex rev-Row--alignStretch">
      <div className="rev-Col rev-Col--small12 rev-Col--large7">
        <h2>Accessible</h2>
        <p className="Lead"><strong>We’re stronger together.</strong> Our designs should be accessible and inclusive to all, regardless of ability, culture, location, or quality of internet access.</p>
      </div>
      <div className="rev-Col rev-Col--large5 SectionDivider"> 
        <h4>Methods</h4>
        <ul className="BulletedList">
          <li>Maintain accessibility standards</li>
          <li>Build responsively</li>
          <li>Understand your audience and build for their needs</li>
        </ul>
      </div>
    </div>
  </div>
  <div className="rev-Card rev-Card--primary">
    <div className="rev-Row rev-Row--flex rev-Row--alignStretch">
      <div className="rev-Col rev-Col--small12 rev-Col--large7">
        <h2>Consistent</h2>
        <p className="Lead"><strong>Consistency breeds stability.</strong> Maintain consistent standards and develop reusable patterns that allow for a predictable user environment.</p>
      </div>
      <div className="rev-Col rev-Col--large5 SectionDivider"> 
        <h4>Methods</h4>
        <ul className="BulletedList">
          <li>Architect reusable design patterns, components, and templates</li>
          <li>Minimize users’ cognitive loads and decision-making time</li>
          <li>Enable users to feel confident when interacting with our products</li>
        </ul>
      </div>
    </div>
  </div>
  <div className="rev-Card rev-Card--primary">
    <div className="rev-Row rev-Row--flex rev-Row--alignStretch">
      <div className="rev-Col rev-Col--small12 rev-Col--large7">
        <h2>Intentional</h2>
        <p className="Lead"><strong>Don’t only make something that works.</strong> Design for experiences that people love to have repeatedly. Craft aesthetically pleasing offerings that carry a consistent meaning in their form and function.</p>
      </div>
      <div className="rev-Col rev-Col--large5 SectionDivider"> 
        <h4>Methods</h4>
        <ul className="BulletedList">
          <li>Focus on essential, functional design</li>
          <li>Build purposeful design solutions</li>
          <li>Reduce distractions</li>
        </ul>
      </div>
    </div>
  </div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      